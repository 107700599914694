import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    place: null,  // 首页产所类型
    sysOrgCode: null, //首页所属机构
    checkLogTime: null,
    secondCheckLogTime: null,
    userId: null,// 获取
    buildName: null,
    sysCounts: null, // 系统概况
    placeCounts: null, // 重点场所
    checkCounts: null,  // 核验人次
    checkLogs: [],  // 实时测温
    healthCodeRadio: null, // 健康码比例
    buildList: [], // 楼宇列表
    isChangeOrgCode: false,
    buildId: null, //楼宇Id
    buildingCounts: null, //第二个屏幕，楼宇概况数据
    buildingCheckCountHour: [], //第二屏，时段核验趋势
    buildingCheckCountDay: [], // 第二屏，核验人次趋势

    deviceList: [], //第三屏，设备列表
    checkLogsList: [], // 第三屏，实时测温列表


    sysInfo: {
      weatherInfo: {
        wea: '暂无'
      }
    },
  },


  mutations: {
    setPlaceValue(state, newValue) {
      state.place = newValue
    },
    setSysOrgCodeValue(state, newValue) {
      state.sysOrgCode = newValue
    },

    setSysCounts(state, newValue) {
      state.sysCounts = newValue
    },

    setPlaceCounts(state, newVal) {
      state.placeCounts = newVal
    },

    setCheckCounts(state, newVal) {
      state.checkCounts = newVal;
    },

    setCheckLogs(state, newVal) {
      state.checkLogs = newVal
    },

    setHealthCodeRadio(state, newVal) {
      state.healthCodeRadio = newVal
    },

    setBuildList(state, newVal) {
      state.buildList = newVal;
    },

    setBuildId(state, newVal) {
      state.buildId = newVal;
    },

    setBuildCounts(state, newVal) {
      state.buildingCounts = newVal;
    },
    setBuildingCheckCountHour(state, newVal) {
      state.buildingCheckCountHour = newVal;
    },
    setBuildingCheckCountDay(state, newVal) {
      state.buildingCheckCountDay = newVal;
    },

    setDeviceList(state, newVal) {
      state.deviceList = newVal;
    },
    setCheckLogsList(state, newVal) {
      state.checkLogsList = newVal;
    },

    setSysInfo(state, newVal) {
      state.sysInfo = newVal;
    },

    setUserId(state, newVal) {
      state.userId = newVal;
    },
    setCheckLogTime(state, newVal) {
      state.checkLogTime = newVal
    },
    setSecondCheckLogTime(state, newVal) {
      state.secondCheckLogTime = newVal
    },

    setBuildingName(state, newVal) {
      state.buildName = newVal
    },

    setIsChangeOrgCode(state, newValue) {
      state.isChangeOrgCode = newValue
    },

    resetState(state, newValue) {
      state = { ...newValue }
    },


  },


  getters: {
    getValue: state => state

  },
  actions: {
  },
  modules: {
  }
})
