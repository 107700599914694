import Vue from 'vue'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './common/flexible'
import dataV from "@jiaminghi/data-view";
import scroll from 'vue-seamless-scroll'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import myaxios from "./util/request.js"
import "./assets/scss/style.scss";
import "vue-awesome/icons/index.js";
import {
  message
} from './util/resetMessage';
// 引入vue-awesome
import Icon from "vue-awesome/components/Icon";

// 全局注册图标
Vue.component("icon", Icon);
// 全局注册 dataV 组件
Vue.use(dataV,scroll);
// 引入全局css





Vue.use(ElementUI)

Vue.prototype.$message = message;

Vue.config.productionTip = false;
// 注册封装好的axios方法挂在在全局变量上 

console.log(myaxios)
Vue.prototype.$http = {...myaxios}
console.log(Vue)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
