import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import router from '../router/index'


// 默认配置
axios.defaults.withCredentials = false
// axios.defaults.timeout = 50000
axios.defaults.headers = { 'X-Requested-With': 'XMLHttpRequest' }
axios.defaults.baseURL = '/jeecg-boot'


// 请求拦截器
axios.interceptors.request.use(
  config => {
    return config
  },
  err => {
    Vue.$message.error('请求错误')
    return Promise.reject(err)
  }
)
// 响应拦截器
axios.interceptors.response.use(
  response => {
    // 请求成功的时候更新token
    const token = response.headers["authorization"];  // 需要服务器设置 'Access-Control-Expose-Headers' : 'Authorization',
    if (token) {
      localStorage.setItem('token', token);
    }

    return response
  },
  error => {
    console.log("错误处理1")
    if (error.response && error.response.status === 401) {
      Vue.prototype.$message.error('登录已过期，请重新登陆！')
      router.replace({
        path: '/login',
      })
    } else if(error.response && error.response.status === 500){
      Vue.prototype.$message.error(error.response.message)
    } else {
      Vue.prototype.$message({
        message: error,
        type: 'error',
      })
      console.log(error.response)
    }

    return Promise.reject(error)
  }
)
// 错误处理
function checkStatus(response) {
  return new Promise((resolve, reject) => {
    if (response.status === 200) {
      resolve(response.data)
    } else {
      console.log("错误处理2")
      reject(response.data)
    }
  })
}

const myaxios = {
  get(url, params){
    return axios({
      method: 'get',
      url,
      params,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Access-Token': `${localStorage.getItem('pro__Access-Token') ? JSON.parse(localStorage.getItem('pro__Access-Token')).value :''}`
      }
    }).then(checkStatus)
  },
  post(url, data) {
    return axios({
      method: 'post',
      url,
      data: qs.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'X-Access-Token': `${localStorage.getItem('pro__Access-Token') ? JSON.parse(localStorage.getItem('pro__Access-Token')).value :''}`
      }
    }).then(checkStatus)
  },
  postLogin(url, data) {
    return axios({
      method: 'post',
      url,
      data: data,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Access-Token': ''
      }
    }).then(checkStatus)
  },
  put(url, data) {
    return axios({
      method: 'put',
      url,
      data: qs.stringify(data)
    }).then(checkStatus)
  },
  putParams(url, params) {
    return axios({
      method: 'put',
      url,
      params
    }).then(checkStatus)
  },
  putForm(url, data) {
    return axios({
      method: 'put',
      url,
      data: qs.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
  },
  delete(url, params) {
    return axios({
      method: 'delete',
      url,
      params
    }).then(checkStatus)
  }

}

export default myaxios