<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("projectValue", JSON.stringify(this.$store.state));
    });

    try {
      sessionStorage.getItem("projectValue") &&
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem("projectValue"))
          )
        );
    } catch (err) {
      console.log(err);
    }
    sessionStorage.removeItem("projectValue");
  },
};
</script>

<style >
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
