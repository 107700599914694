import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
  path: '/index',
  name: 'index',
  component: () => import('../views/index.vue')
},
{
  path: '/second',
  name: 'second',
  component: () => import('../views/SecondPage/index.vue')
},
{
  path: '/thirdPage',
  name: 'thirdPage',
  component: () => import('../views/ThirdPage/index.vue')
},{
  path: '/login',
  name: 'login',
  component: () => import('../views/login/login.vue')
}]
// const router = new VueRouter({
//   mode: "history",
//   base: 'web-screen',
//   routes
// })

// export default router

export default new VueRouter({
  mode: 'history',
  base: 'web-screen',
  routes
})